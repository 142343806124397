import React,{useState} from 'react'
import styled from 'styled-components'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Snackbar } from '@mui/material';

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
z-index: 1;
align-items: center;
@media (max-width: 960px) {
    padding: 0px;
}
`

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 1350px;
padding: 0px 0px 80px 0px;
gap: 12px;
@media (max-width: 960px) {
    flex-direction: column;
}
`

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;


const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
`



const Contact = () => {

  //hooks
  const [open, setOpen] = React.useState(false);
  const form = useRef();
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // ========== Email Validation start here ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ========== Email Validation end here ================

  const handleSend = (e) => {
    e.preventDefault();
    if (username === "") {
      setErrMsg("Username is required!");
      setSuccessMsg("")
    } else if (phoneNumber === "") {
      setErrMsg("Phone number is required!");
      setSuccessMsg("")
    } else if (email === "") {
      setErrMsg("Please give your Email!");
      setSuccessMsg("")
    } else if (!emailValidation(email)) {
      setErrMsg("Give a valid Email!");
      setSuccessMsg("")
    } else if (subject === "") {
      setErrMsg("Plese give your Subject!");
      setSuccessMsg("")
    } else if (message === "") {
      setErrMsg("Message is required!");
      setSuccessMsg("")
    } else {
      setSuccessMsg(
        `Hello dear ${username}, Your Message has been sent Successfully. Thank you for reaching out, I will get back to you at the earliest! `
      );
      setErrMsg("");
      setUsername("");
      setPhoneNumber("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_tox7kqs', 'template_nv7k7mj', form.current, 'SybVGsYS52j2TfLbi')
      .then((result) => {
        setOpen(true);
        form.current.reset();
      }, (error) => {
        console.log(error.text);
      });
  }



  return (
    <Container>
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Feel free to reach out to me for any questions or opportunities!</Desc>
        <ContactForm ref={form} onSubmit={handleSubmit}>
          <ContactTitle>Email Me 🚀</ContactTitle>
          <ContactInput placeholder="Your Email" name="from_email" onChange={(e) => setEmail(e.target.value)} value={email}  />
          <ContactInput placeholder="Your Name" name="from_name" onChange={(e) => setUsername(e.target.value)} value={username}/>
          <ContactInput placeholder="Subject" name="subject" onChange={(e) => setSubject(e.target.value)} value={subject}/>
          <ContactInput placeholder="Phone Number" name="phonenumber" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}/>  
          <ContactInputMessage placeholder="Message" rows="4" name="message" onChange={(e) => setMessage(e.target.value)} value={message}/>
          <ContactButton type="submit" value="Send" onClick={handleSend}/>
        </ContactForm>
        {errMsg && (
                <p style={{
                  padding: "0.75rem 0",
                  background: "linear-gradient(to right, #1e2024, #23272b)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",  // Assuming shadow-shadowOne is a typical shadow
                  textAlign: "center",
                  color: "#ef4444",  // Corresponds to orange-500 in Tailwind
                  fontSize: "1rem",  // Corresponds to text-base (16px)
                  letterSpacing: "0.05em",  // Rough equivalent to tracking-wide
                  animation: "bounce 1s infinite"  // Tailwind's animate-bounce equivalent
                }}>
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p style={{
                  padding: "0.75rem 2rem",
                  background: "linear-gradient(to right, #1e2024, #23272b)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",  // Assuming shadow-shadowOne is a typical shadow
                  textAlign: "center",
                  borderRadius: "12px",  
                  color: "#22c55e",  // Corresponds to green-500 in Tailwind
                  fontSize: "1rem",  // Corresponds to text-base (16px)
                  letterSpacing: "0.05em",  // Rough equivalent to tracking-wide
                  animation: "bounce 1s infinite"  // Tailwind's animate-bounce equivalent
                }}>
                  {successMsg}
                </p>
              )}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={()=>setOpen(false)}
          message="Email sent successfully!"
          severity="success"
        />
      </Wrapper>
    </Container>
  )
}

export default Contact